import api from "../utils/api";
import { setAlert } from "./alertAction";
import { ADMIN_LOAD, LOADING } from "./types";
import i18n from "../i18n";

export const loadUserList =
  ({ id, type }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      dispatch({ type: LOADING, payload: true });
      const res = await api.get(
        `/loadUserList?user_id=${id}&user_type=${type}`,
        config
      );
      dispatch({
        type: ADMIN_LOAD,
        payload: res.data,
      });
      dispatch({ type: LOADING, payload: false });
      // dispatch(setAlert(
      //   i18n.t(`response.${res.data.key}`),
      //   "green"
      // ))
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        dispatch({ type: LOADING, payload: false });
        errors.forEach((error) =>
          dispatch(
            setAlert(i18n.t(`response.${error.response.data.key}`), "red")
          )
        );
      }
    }
  };

export const lastLoginDetails = () => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    dispatch({ type: LOADING, payload: true });
    const res = await api.get(`/lastLoginDetails`, config);
    dispatch({ type: LOADING, payload: false });
    // dispatch(setAlert(
    //   i18n.t(`response.${res.data.data.key}`),
    //   "green"
    // ))
    return res.data.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch({ type: LOADING, payload: false });
      errors.forEach((error) =>
        dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"))
      );
    }
  }
};

export const changeStatus =
  ({ user_id, type, id, status }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const formData = {
        login_user_id: user_id,
        user_type: type,
        user_id: id,
        user_status: status,
        language: i18n.language,
      };
      console.log(formData);
      // const { language } = i18n;
      dispatch({ type: LOADING, payload: true });
      const res = await api.put("/changeUserStatus", formData, config);
      dispatch(loadUserList({ id: user_id, type: type }));
      dispatch({ type: LOADING, payload: false });
      dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        dispatch({ type: LOADING, payload: false });
        errors.forEach((error) =>
          dispatch(
            setAlert(i18n.t(`response.${error.response.data.key}`), "red")
          )
        );
      }
    }
  };

export const deleteUser =
  ({ user_id, type, id }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const formData = {
        login_user_id: user_id,
        user_type: type,
        user_id: id,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch({ type: LOADING, payload: true });
      const res = await api.delete("/deleteUser", {
        data: formData,
        ...config,
      });
      dispatch(loadUserList({ id: user_id, type }));
      dispatch({ type: LOADING, payload: false });
      dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
    } catch (err) {
      dispatch(setAlert(i18n.t(`response.${err.response.data.key}`), "red"));
    }
  };

export const editEmailCount = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const res = await api.post("/admin/editEmailCount", formData);
    dispatch({ type: LOADING, payload: false });
    dispatch(loadUserList());
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch({ type: LOADING, payload: false });
      errors.forEach((error) => dispatch(setAlert(error.msg, "red")));
    }
  }
};

export const getRoles = () => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    dispatch({ type: LOADING, payload: true });
    const res = await api.get(`/roles`, config);
    dispatch({ type: LOADING, payload: false });
    return res.data.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch({ type: LOADING, payload: false });
      errors.forEach((error) =>
        dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"))
      );
    }
  }
};

export const getPermissions = () => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    dispatch({ type: LOADING, payload: true });
    const res = await api.get(`/permissions`, config);
    dispatch({ type: LOADING, payload: false });
    return res.data.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch({ type: LOADING, payload: false });
      errors.forEach((error) =>
        dispatch(setAlert(i18n.t(`response.${error.response.data.key}`), "red"))
      );
    }
  }
};

export const assignRolesWithPermission =
  ({ assignRole, permissions }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "Application/json",
        },
      };

      dispatch({ type: LOADING, payload: true });
      const res = await api.post(
        `/roles/${assignRole}/permissions`,
        { permissions },
        config
      );
      dispatch({ type: LOADING, payload: false });
    } catch (err) {
      const errors = err.response.data.errors;

      if (errors) {
        dispatch({ type: LOADING, payload: false });
        errors.forEach((error) =>
          dispatch(
            setAlert(i18n.t(`response.${error.response.data.key}`), "red")
          )
        );
      }
    }
  };
