import api from "../utils/api";
import axios from "axios";
import { LOADING, EMAIL_LOAD, EMAIL_HISTORY_LOAD } from "./types";
import { setAlert } from "./alertAction";
import i18n from "../i18n";

export const loadTempEmails =
  ({ id, value }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      // Set token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      
      dispatch({ type: LOADING, payload: true });
      const res = await api.get(
        `/loadEmails?user_id=${id}&user_type=${value}`,
        config
      );
      dispatch({
        type: EMAIL_LOAD,
        payload: res.data.data,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };
export const loadRecycleEmails =
  ({ id, value }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      // Set token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      dispatch({ type: LOADING, payload: true });
      const res = await api.get(
        `/loadEmails?user_id=${id}&user_type=${value}&recycle_bin=1`,
        config
      );

      dispatch({
        type: EMAIL_LOAD,
        payload: res.data.data,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };

export const createAuto =
  ({ user_id, user_type }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch({ type: LOADING, payload: true });
      const res = await api.post(
        "/createEmailAuto",
        { user_id, user_type },
        config
      );

      dispatch(loadTempEmails({ id: user_id, value: user_type }));
      dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };

export const createManual =
  ({ user_id, temp_email, password, user_type }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch({ type: LOADING, payload: true });
      const res = await api.post(
        "/createEmailManual",
        { user_id, temp_email, password },
        config
      );

      dispatch(loadTempEmails({ id: user_id, value: user_type }));

      dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));

      dispatch({ type: LOADING, payload: false });
      return res
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };

export const generatesWithAi =
  ({ user_id, user_type }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch({ type: LOADING, payload: true });
      const res = await api.post(
        "/generatesWithAi",
        { user_id, user_type },
        config
      );

      dispatch(loadTempEmails({ id: user_id, value: user_type }));
      dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };

export const initEmailList =
  ({ id, value }) =>
  async (dispatch) => {
    try {
      dispatch({ type: LOADING, payload: true });
      dispatch(loadTempEmails({ id, value }));
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };

export const loadEmailHistory =
  ({ id, value, temp_email_id }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      // Set token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch({ type: LOADING, payload: true });
      const res = await api.get(
        `/emailHistories?user_id=${id}&user_type=${value}&temp_email_id=${temp_email_id}`,
        config
      );

      dispatch({
        type: EMAIL_HISTORY_LOAD,
        payload: res.data.data,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      if (error) {
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
        dispatch({ type: LOADING, payload: false });
      }
    }
  };
export const loadEmailHistoryAll =
  ({ id, value }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      // Set token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      dispatch({ type: LOADING, payload: true });
      const res = await api.get(
        `/emailHistories?user_id=${id}&user_type=${value}`,
        config
      );

      dispatch({
        type: EMAIL_HISTORY_LOAD,
        payload: res.data.data,
      });
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };

export const changeStatus =
  ({ temp_email_id, temp_email, origin, target, id, user_type }) =>
  async (dispatch) => {
    if (origin === "deleted") {
      try {
        const token = sessionStorage.getItem("token");

        // Set token in the Authorization header
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
        dispatch({ type: LOADING, payload: true });
        const res = await api.post(
          "/changeStatus",
          { temp_email_id, temp_email, target, origin, id },
          config
        );
        dispatch({ type: LOADING, payload: false });

        dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));

        dispatch(loadRecycleEmails({ id, value: user_type }));
      } catch (error) {
        if (error) {
          dispatch({ type: LOADING, payload: false });
          dispatch(
            setAlert(i18n.t(`response.${error.response.data.key}`), "red")
          );
        }
      }
    } else {
      try {
        const token = sessionStorage.getItem("token");

        // Set token in the Authorization header
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };
        dispatch({ type: LOADING, payload: true });
        const res = await api.post(
          "/changeStatus",
          { temp_email_id, temp_email, target, origin, id },
          config
        );
        dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
        dispatch({ type: LOADING, payload: false });
        dispatch(loadTempEmails({ id, value: user_type }));
      } catch (error) {
        if (error) {
          dispatch({ type: LOADING, payload: false });
          dispatch(
            setAlert(i18n.t(`response.${error.response.data.key}`), "red")
          );
        }
      }
    }
  };

export const lockEmail =
  ({ temp_email_id, reason, user_id, email_lock_status, type }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const { language } = i18n;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      dispatch({ type: LOADING, payload: true });
      const res = await api.post(
        "/email_lock_unlock",
        {
          temp_email_id,
          reason,
          user_id,
          email_lock_status,
          language,
        },
        config
      );
      dispatch(loadTempEmails({ id: user_id, value:type }));
      dispatch({ type: LOADING, payload: false });
      dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };

export const unlockEmail =
  ({ temp_email_id, reason, user_id, email_lock_status, type }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");
      const { language } = i18n;

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      dispatch({ type: LOADING, payload: true });
      const res = await api.post(
        "/email_lock_unlock",
        {
          temp_email_id,
          reason,
          user_id,
          email_lock_status,
          language,
        },
        config
      );
      dispatch({ type: LOADING, payload: false });
      dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
      dispatch(loadTempEmails({ id: user_id, value:type }));
      dispatch({ type: LOADING, payload: false });
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };

export const deleteEmail =
  ({ temp_email_id, origin, user }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      // Set token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      dispatch({ type: LOADING, payload: true });

      const res = await api.delete("/deleteEmail", {
        ...config,
        data: { temp_email_id, origin },
      });
      dispatch(loadTempEmails({ id: user.id, value: user.user_type }));
      dispatch({ type: LOADING, payload: false });

      dispatch(setAlert(i18n.t(`response.${res.data.key}`), "green"));
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(i18n.t(`response.${error.response.data.key}`), "red")
        );
      }
    }
  };

export const editEmailFunc = (formData) => async (dispatch) => {
  try {
    dispatch({ type: LOADING, payload: true });
    const res = await api.post("/temporary/editEmail", formData);
    dispatch({ type: LOADING, payload: false });
    dispatch(loadTempEmails());
    dispatch(
      setAlert("This temporary email was updated successfully!", "green")
    );
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch({ type: LOADING, payload: false });
      errors.forEach((error) => dispatch(setAlert(error.msg, "red")));
    }
  }
};
