import api from "../utils/api";
import { loadUser } from "./authAction";
import { setAlert } from "./alertAction";
import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOG_OUT,
  LOADING,
} from "./types";
import i18n from "../i18n";


export const contactUs = (formData) => async (dispatch) => {
  try {
    const token = sessionStorage.getItem("token");
    const { language } = i18n;
    formData.language = language
    console.log(formData)
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    dispatch({ type: LOADING, payload: true });
    const res = await api.post("/contactUs", formData, config);
      dispatch({ type: LOADING, payload: false });
      dispatch(setAlert(
        i18n.t(`response.${res.data.key}`),
        "green"
      ))
    
  } catch (error) {
    if (error) {
      dispatch({ type: LOADING, payload: false });
      dispatch(setAlert(
        i18n.t(`response.${error.response.data.key}`),
        "red"
      ))
    }
  }
};

export const updateProfile =
  ({ first_name, last_name, country_code, mobile, user_id, email }) =>
  async (dispatch) => {
    try {
      const token = sessionStorage.getItem("token");

      // Set token in the Authorization header
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      dispatch({ type: LOADING, payload: true });
      const res = await api.patch(
        "/updateProfile",
        { first_name, last_name, country_code, mobile, user_id, email },
        config
      );

      //dispatch(setAlert("Your profile was updated successfully!", "green"));
      dispatch(loadUser(user_id, email));
      dispatch({ type: LOADING, payload: false });
      dispatch(setAlert(
        i18n.t(`response.${res.data.key}`),
        "green"
      ))
      return res.data;
    } catch (error) {
      if (error) {
        dispatch({ type: LOADING, payload: false });
        dispatch(
          setAlert(
            i18n.t(`response.${error.response.data.key}`),
            "red"
          )
        )
      }
    }
  };

export const changePassword = (formData) => async (dispatch) => {
  try {
    const { newPassword, confirmPassword } = formData;
    if (newPassword != confirmPassword) {
      dispatch(setAlert("New password does not match!", "red"));
      return;
    }

    dispatch({ type: LOADING, payload: true });
    const res = await api.post("/user/changePassword", formData);
    dispatch({ type: LOADING, payload: false });
    dispatch(setAlert("Your password was changed successfully!", "green"));
    return res.data;
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      dispatch({ type: LOADING, payload: false });
      errors.forEach((error) =>
      dispatch(
        setAlert(
          i18n.t(`response.${error.response.data.key}`),
          "red"
        )
      ));
    }
  }
};
