import React from "react";
import "./layout.css";
import { NavLink, Link } from "react-router-dom";
import { LANGUAGES } from "../../constants/Languages";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import PropTypes from "prop-types";

//Styling....
import {
  Navbar,
  Typography,
  Drawer,
  Collapse,
  ButtonGroup,
  Button,
  IconButton,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import {
  PresentationChartBarIcon,
  ShoppingBagIcon,
  UserCircleIcon,
  Cog6ToothIcon,
  InboxIcon,
  PowerIcon,
} from "@heroicons/react/24/solid";

const HeaderTwo = ({ isAuthenticated, user_type }) => {
  const { i18n, t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const openDrawer = () => setOpen(true);
  const closeDrawer = () => setOpen(false);

  return (
    <>
      {isAuthenticated ? (
        <Navbar className="w-full shadow-none bg-opacity-100 backdrop-blur-none backdrop-saturate-0 bg-gray-100 rounded-none px-4 py-2 lg:px-8 lg:py-4 max-w-full">
          <div className="container mx-auto flex items-center justify-between gap-3">
            <Link to={user_type === 2 ? "/temporary" : "/user_mng"}>
              <Typography className="cursor-pointer py-1.5 font-medium text-2xl text-black">
                Defender Mail
              </Typography>
            </Link>
            <div className="hidden md:flex flex-col items-end md:flex-row md:justify-between md:items-center md:flex-1">
              <div className="flex items-center">
                <Link to={user_type === 2 ? "/temporary" : "/temporary_admin"}>
                  <ListItem className="text-black">
                    <ListItemPrefix>
                      <InboxIcon className="h-5 w-5" />
                    </ListItemPrefix>
                    {t("lang.navbar.button")}
                  </ListItem>
                </Link>
              </div>
              <div className="flex items-center gap-x-4 ml-auto">
                <Link
                  to="/profile"
                  className="flex items-center space-x-3 cursor-pointer"
                >
                  <Typography className="text-black">
                    {t("lang.footerB.button1")}
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#000000"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </Link>
              </div>
            </div>
            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent md:hidden"
              onClick={openDrawer}
            >
              {open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="#000000"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="#000000"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>

            {/* Routing for tempEmails for admin,user type */}
            <Drawer
              open={open}
              onClose={closeDrawer}
              className="bg-transparent h-screen"
            >
              <div className="bg-gray-100 flex flex-col">
                <div className="flex items-center justify-between px-4">
                  <Link
                    to={user_type === 2 ? "/temporary" : "/temporary_admin"}
                  >
                    <ListItem className="text-black">
                      <ListItemPrefix>
                        <InboxIcon className="h-5 w-5" />
                      </ListItemPrefix>
                      {t("lang.navbar.button")}
                    </ListItem>
                  </Link>
                  <IconButton
                    className="float-right"
                    variant="text"
                    color="blue-gray"
                    onClick={closeDrawer}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={2}
                      stroke="currentColor"
                      className="h-5 w-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </IconButton>
                </div>
                <Link
                  to="/profile"
                  className="flex items-center space-x-3 cursor-pointer px-4 ml-3"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#000000"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  <Typography className="text-black">
                    {t("lang.footerB.button1")}
                  </Typography>
                </Link>
              </div>
            </Drawer>
          </div>
        </Navbar>
      ) : (
        ""
      )}
    </>
  );
};

HeaderTwo.propTypes = {
  user_type: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  user_type: state.authReducer.user_type,
});

export default connect(mapStateToProps)(HeaderTwo);
