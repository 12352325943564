import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Sidebar from "../layout/Sidebar";
import Spinner from "../layout/Spinner";
import { Divider } from "@mui/material";
import { Select, Option } from "@material-tailwind/react";

import {
  Checkbox,
  Card,
  List,
  ListItem,
  ListItemPrefix,
  Button,
  Typography,
} from "@material-tailwind/react";

import { useIdleDetection } from "../Inactive/useIdleDetection";
import { logout } from "../../actions/authAction";
import {
  getRoles,
  getPermissions,
  assignRolesWithPermission,
} from "../../actions/adminAction";

const Roles = ({
  loading,
  logout,
  getRoles,
  getPermissions,
  assignRolesWithPermission,
}) => {
  const [loadRoles, setRoles] = useState([]);
  const [loadPermissions, setLoadPermissions] = useState([]);

  const [assignRole, setSetAssignRole] = useState(null);
  const [permissions, setpermissions] = useState([]);

  const isIdle = useIdleDetection(600000); // 10 minutes

  if (isIdle) {
    logout();
  }

  const handleCheckboxChange = (event) => {
    const checkedId = event.target.value;
    if (event.target.checked) {
      setpermissions([...permissions, checkedId]);
    } else {
      setpermissions(permissions.filter((id) => id !== checkedId));
    }
  };

  const handleRoles = async () => {
    assignRolesWithPermission({ assignRole, permissions })
      .then((res) => {
        setSetAssignRole(null);
        setpermissions([]);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getRoles()
      .then((res) => setRoles(res))
      .catch((err) => setRoles(err));
  }, []);

  useEffect(() => {
    getPermissions()
      .then((res) => setLoadPermissions(res))
      .catch((err) => setLoadPermissions(err));
  }, []);

  return (
    <div className="flex flex-col lg:flex-row pb-6 w-full">
      <Sidebar />
      <Divider orientation="vertical" flexItem />
      {loading ? (
        <div className="w-full flex justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col mt-8 px-4 w-full">
          <div className="grid grid-cols-2">
            <Typography>Roles</Typography>

            <Select onChange={(e) => setSetAssignRole(e)}>
              {loadRoles.map((role) => (
                <Option key={role.id} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          </div>
          <Divider orientation="vertical" flexItem />
          <div className="flex flex-col">
            <Typography>Permissions</Typography>

            <List className="grid grid-cols-2 gap-x-4">
              {loadPermissions.map((permit) => (
                <ListItem className="p-0" key={permit.id}>
                  <label
                    htmlFor={permit.id}
                    className="flex w-full cursor-pointer items-center px-3 py-2"
                  >
                    <ListItemPrefix className="mr-3">
                      <Checkbox
                        id={permit.id}
                        ripple={true}
                        className="hover:before:opacity-0 checked:bg-black"
                        containerProps={{
                          className: "p-0",
                        }}
                        value={permit.name}
                        checked={permissions.includes(permit.name)}
                        onChange={(event) => {
                          handleCheckboxChange(event);
                        }}
                      />
                    </ListItemPrefix>
                    <Typography color="blue-gray" className="font-medium">
                      {permit.name}
                    </Typography>
                  </label>
                </ListItem>
              ))}
            </List>
          </div>
          <Button
            onClick={handleRoles}
            className="mt-6 bg-black shadow-none  hover:shadow-none w-1/2 self-center"
          >
            Set permissions for roles
          </Button>
        </div>
      )}
    </div>
  );
};

Roles.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, {
  getRoles,
  getPermissions,
  assignRolesWithPermission,
  logout,
})(Roles);
