import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

import { updateProfile, changePassword } from "../../actions/userAction";
import {
  Typography,
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Input,
} from "@material-tailwind/react";

import "react-datepicker/dist/react-datepicker.css";
import Divider from "@mui/material/Divider";
import Sidebar from "../layout/Sidebar";
import Profile_sidebar from "../layout/Profile_sidebar";
import Foot_but from "../layout/Foot_but";
import { logout } from "../../actions/authAction";
import { useIdleDetection } from "../Inactive/useIdleDetection";

const Profile = ({ user, updateProfile, changePassword }) => {
  const [inputData, setInputData] = useState({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    user_id: user.id,
    country_code: user.country_code,
    mobile: user.mobile,
    curPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const isIdle = useIdleDetection(600000); // 10 minutes

  const [value, setValue] = useState(user.mobile);

  let { first_name, last_name, mobile, country_code } = inputData;

  const [open, setOpen] = React.useState(false);
  const [openPwd, setOpenPwd] = React.useState(false);

  const [dis, setDis] = useState(true);

  const handleOpen = () => setOpen((cur) => !cur);
  const handleOpenPwd = () => setOpenPwd((cur) => !cur);

  const onChangeInput = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  if (isIdle) {
    logout();
  }

  const onSubmit = (e) => {
    e.preventDefault();
    updateProfile({
      first_name,
      last_name,
      country_code,
      mobile,
      user_id: user.id,
      email: user.email,
    });
  };

  const splitMobileNumb = () => {
    let mobileNumb = formatPhoneNumberIntl(value).split(/^(\+\d{1,4})(\d+)$/);
    let cc = mobileNumb[0].split(" ");
    let mainNumb = cc.slice(1).join("");

    setInputData({ ...inputData, country_code: cc[0], mobile: mainNumb });
  };

  useEffect(() => {
    splitMobileNumb();
    // setDis(!isValidPhoneNumber(String(value)));
    if (first_name.length > 1) {
      setDis(false);
    } else setDis(true);
  }, [first_name]);
  return (
    <div className="flex flex-col lg:flex-row pb-6 w-full">
      {user.user_type === 2 ? <Profile_sidebar /> : <Sidebar />}
      {/* <Profile_sidebar /> */}
      <Divider orientation="vertical" flexItem />
      <div className="w-full flex flex-col space-y-4 md:space-y-0">
        <Dialog
          size="xs"
          open={open}
          handler={handleOpen}
          className="bg-transparent shadow-none"
        >
          <Card className="">
            <form onSubmit={onSubmit}>
              <Typography variant="h5" className="flex justify-center mt-4 ">
                Edit Profile
              </Typography>
              <CardBody className="flex flex-col gap-4">
                <Input
                  label="First Name"
                  size="lg"
                  name="first_name"
                  value={first_name}
                  onChange={onChangeInput}
                  required
                />
                <Input
                  label="Last Name"
                  size="lg"
                  name="last_name"
                  value={last_name}
                  onChange={onChangeInput}
                />

                <PhoneInput
                  placeholder="Enter phone number"
                  value={value}
                  onChange={setValue}
                  className="py-2 px-4"
                />
              </CardBody>
              <CardFooter className="pt-0">
                <Button
                  type="submit"
                  className="!bg-black"
                  disabled={dis}
                  onClick={handleOpen}
                  fullWidth
                >
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </Dialog>

        <div className="flex flex-col gap-0 md:flex-row justify-around items-center mt-6 px-4">
          <div className="space-y-4">
            <div className="flex flex-col md:flex-row md:items-center space-x-0 md:space-x-4">
              <Typography className="text-lg">
                Name: {user.first_name} {user.last_name}
              </Typography>
            </div>

            <Typography className="text-lg">
              Mobile:{user.country_code} {user.mobile}
            </Typography>

            <Typography className="text-lg">Email: {user.email}</Typography>
          </div>

          <Button
            variant="outlined"
            className="border-none rounded bg-black text-white ml-auto self-start disabled:opacity-30"
            onClick={handleOpen}
          >
            Edit Personal Info
          </Button>
        </div>
        {user.user_type === 2 ? <Foot_but user={user} /> : ""}
      </div>
    </div>
  );
};

Profile.propTypes = {
  user: PropTypes.object,
  logout: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps, {
  updateProfile,
  changePassword,
  logout,
})(Profile);
