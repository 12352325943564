import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Typography,
  Button,
  ButtonGroup,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Input,
  CardHeader,
  Chip,
  IconButton,
  Tooltip,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import {
  PencilIcon,
  ArchiveBoxIcon,
  PlayIcon,
  StopIcon,
  LockClosedIcon,
  LockOpenIcon,
  RectangleStackIcon,
} from "@heroicons/react/24/solid";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";

import {
  ArrowDownTrayIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

import {
  initEmailList,
  changeStatus,
  lockEmail,
  unlockEmail,
  loadEmailHistory,
  loadEmailHistoryAll,
} from "../../actions/tempAction";
import { loadUserList } from "../../actions/adminAction";
import { Select, Option } from "@material-tailwind/react";

import { useTranslation } from "react-i18next";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";

import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

import MenuItem from "@mui/material/MenuItem";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Sidebar from "../layout/Sidebar";
import EmailHistory from "../history/EmailHistory";
import Spinner from "../layout/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { blueGrey } from "@mui/material/colors";

import { logout } from "../../actions/authAction";
import { useIdleDetection } from "../Inactive/useIdleDetection";

const Temporary_admin = ({
  historyList,
  user,
  emailList,
  userList,
  initEmailList,
  loadUserList,
  changeStatus,
  lockEmail,
  unlockEmail,
  loadEmailHistory,
  loading,
  logout,
}) => {
  const [search, setSearch] = React.useState("");
  const [pageSize, setPageSize] = React.useState("5");
  const [firstIndex, setFirstIndex] = React.useState(0);
  const [lastIndex, setLastIndex] = React.useState(4);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [openManual, setOpenManual] = React.useState(false);
  const [isOther, setOther] = React.useState(true);
  const [reason, setReason] = React.useState("Suspicious activity");
  const [selectedTempEmailId, setSelectedTempEmailId] = useState(null);
  const [tempemailid, settempemailid] = useState(null);
  const [size, setSize] = React.useState(null);
  const { t } = useTranslation();
  const isIdle = useIdleDetection(600000); // 10 minutes

  const TABLE_HEAD = [
    t("lang.tableHead2.1"),
    t("lang.tableHead2.2"),
    t("lang.tableHead2.8"),
    t("lang.tableHead2.3"),
    t("lang.tableHead2.4"),
    t("lang.tableHead2.5"),
    t("lang.tableHead2.6"),
    t("lang.tableHead2.7"),
  ];
  const TABLE2_HEAD = [
    t("lang.tableHead3.1"),
    t("lang.tableHead3.2"),
    t("lang.tableHead3.3"),
    t("lang.tableHead3.4"),
    t("lang.tableHead3.5"),
    t("lang.tableHead3.8"),
    t("lang.tableHead3.6"),
    t("lang.tableHead3.7"),
  ];

  const handleOpen = (value) => setSize(value);
  const handleReason = (isOther, reason) => {
    setOther(isOther);
    setReason(reason);
  };

  const handleOpenManual = () => setOpenManual((cur) => !cur);

  if (isIdle) {
    logout();
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const emailListData = emailList.length !== 0 ? emailList : [];
  const emailhistorylist = historyList.length !== 0 ? historyList : [];
  // const emailhistorylist= historyList;

  let getData = [];
  let searchData = [];
  let getEmailData = [];

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handlePrevious = () => {
    const newIndex = Math.max(0, firstIndex - parseInt(pageSize));
    setFirstIndex(newIndex);
    setLastIndex(
      Math.min(emailListData.length - 1, newIndex + parseInt(pageSize) - 1)
    );
    setPageIndex(newIndex);
  };

  const handleNext = () => {
    if (lastIndex < emailListData.length - 1) {
      const newIndex = Math.min(emailListData.length - 1, lastIndex + 1);
      setFirstIndex(newIndex);
      setLastIndex(
        Math.min(emailListData.length - 1, newIndex + parseInt(pageSize) - 1)
      );
      setPageIndex(newIndex);
    }
  };

  const handlePageSize = (value) => {
    setPageSize(value);
  };

  useEffect(() => {
    initEmailList({ id: user.id, value: user.user_type });
    setLastIndex(pageSize - 1);
  }, [pageSize]);
  let countData = 0;
  let countDataEmail = 0;

  for (let i = 0; i < emailListData.length; i++) {
    const username =
      emailListData[i].user_data.first_name &&
      emailListData[i].user_data.last_name
        ? `${emailListData[i].user_data.first_name} ${emailListData[i].user_data.last_name}`
        : "null";
    if (
      (emailListData[i] &&
        (emailListData[i].temp_email
          .toLowerCase()
          .includes(search.toLowerCase()) ||
          emailListData[i].user_data.email
            .toLowerCase()
            .includes(search.toLowerCase()))) ||
      username.toLowerCase().includes(search.toLowerCase())
    ) {
      searchData.push(emailListData[i]);
      countData += 1;
    }
  }
  // Loop to push each item of historyList into getEmailData
  for (const item of emailhistorylist) {
    if (item && item.histories) {
      for (let val of item.histories) {
        if (
          val.prev_state == null ||
          val.reason == null ||
          val.current_state == null ||
          val.user_data == null ||
          val.reason == null
        ) {
          continue;
        }
        getEmailData.push(val); // Spread operator to flatten the histories array
        // getEmailData.push(...item.histories); // Spread operator to flatten the histories array
        countDataEmail += item.histories.length;
      }
    }
  }

  const get_table_data = (firstIndex, lastIndex) => {
    for (let i = firstIndex; i <= lastIndex; i++) {
      if (searchData[i]) {
        getData.push(searchData[i]);
      }
    }
  };

  get_table_data(firstIndex, lastIndex);
  const TABLE_ROWS = getData.map(
    (
      {
        id,
        temp_email,
        temp_password,
        email_status,
        email_lock_status,
        user_data,
      },
      index
    ) => {
      const { first_name, last_name, email } = user_data;
      return {
        id: id,
        no: pageIndex + index + 1,
        username:
          first_name && last_name ? `${first_name} ${last_name}` : "null",
        email,
        temp_email,
        password: temp_password,
        status: email_status.status,
        lock_status: email_lock_status.lock_status,
      };
    }
  );

  const TABLE2_ROWS = getEmailData.map(
    (
      { id, user_data, prev_state, current_state, created_at, op_type, reason },
      index
    ) => {
      return {
        no: pageIndex + index + 1,
        id: id,
        name: user_data.first_name,
        email: user_data.email,
        previous_state: prev_state.status,
        current_state: current_state.status,
        modified_date: created_at,
        type: op_type,
        reason: reason,
      };
    }
  );

  const getColor = (status) => {
    if (status === "active") return "green";
    else if (status === "inactive") return "yellow";
    else if (status === "deleted") return "blue";
    else if (status === "locked") return "red";
  };

  const setLockEmailInfo = (temp_email_id) => {
    setSelectedTempEmailId(temp_email_id);
    handleOpenManual();
  };

  const settempemailidinfo = async (id) => {
    settempemailid(id);

    await loadEmailHistory({
      id: user.id,
      value: user.user_type,
      temp_email_id: id,
    });
    handleOpen("xl");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await lockEmail({
      temp_email_id: selectedTempEmailId,
      reason,
      user_id: user.id,
      email_lock_status: 1,
      type: user.user_type,
    });
    handleOpenManual();
  };

  return (
    <>
      <div style={{ width: "80%" }}>
        <Dialog
          open={
            size === "xs" ||
            size === "sm" ||
            size === "md" ||
            size === "lg" ||
            size === "xl" ||
            size === "xxl"
          }
          size={size || "md"}
          handler={handleOpen}
        >
          <DialogHeader className="mb-0 pb-0">
            {t("lang.tempAdmin.obj1")}
          </DialogHeader>
          <DialogBody className="h-[70dvh] overflow-scroll pt-0">
            <CardBody className="px-0">
              <div className="overflow-scroll">
                <table className="w-full pb-6 text-center">
                  <thead>
                    <tr className="border-y border-blue-gray-100 bg-blue-gray-50">
                      {TABLE2_HEAD.map((head) => (
                        <th key={head} className="p-4">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  {TABLE2_ROWS.length === 0 ? (
                    <td className="text-center">{t("lang.noHistory")}</td>
                  ) : (
                    <tbody>
                      {TABLE2_ROWS.map(
                        (
                          {
                            no,
                            id,
                            name,
                            email,
                            previous_state,
                            current_state,
                            modified_date,
                            type,
                            reason,
                          },
                          index
                        ) => {
                          const isLast = index === TABLE_ROWS.length - 1;
                          const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";
                          let options = {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          };
                          const formattedDate = new Date(
                            modified_date
                          ).toLocaleDateString(undefined, options);
                          return (
                            <tr key={no}>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-bold"
                                  >
                                    {no}
                                  </Typography>
                                </div>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {name}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {email}
                                </Typography>
                              </td>
                              <td className={`${classes} space-y-3`}>
                                <Chip
                                  size="sm"
                                  variant="ghost"
                                  value={previous_state}
                                  color={
                                    previous_state == "active"
                                      ? "green"
                                      : "yellow"
                                  }
                                />
                              </td>
                              <td className={`${classes} space-y-3`}>
                                <Chip
                                  size="sm"
                                  variant="ghost"
                                  value={current_state}
                                  color={
                                    current_state == "active"
                                      ? "green"
                                      : "yellow"
                                  }
                                />
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {reason == null ? "N/A" : reason}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {formattedDate}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {type}
                                </Typography>
                              </td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </CardBody>
          </DialogBody>
          <DialogFooter className="space-x-2">
            <Button
              type="button"
              className="!bg-black text-white"
              onClick={handleOpen}
            >
              {t("lang.tempAdmin.obj2")}
            </Button>
          </DialogFooter>
        </Dialog>
      </div>

      <div className="flex flex-col lg:flex-row pb-6 w-full">
        <Sidebar />
        <Divider orientation="vertical" flexItem />

        <div className="w-full flex flex-col">
          <Dialog
            size="xl"
            open={openManual}
            handler={handleOpenManual}
            className="bg-transparent shadow-none"
          >
            <Card>
              <form onSubmit={onSubmit}>
                <Typography
                  variant="h4"
                  className="flex justify-center mt-4 font-serif"
                >
                  {t("lang.tempAdmin.obj3")}
                </Typography>
                <CardBody className="flex flex-col gap-4">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue={t("lang.tempAdmin.obj4")}
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="Suspicious activity"
                        control={<Radio />}
                        label={t("lang.tempAdmin.obj4")}
                        color="gray"
                        onClick={() =>
                          handleReason(true, `${t("lang.tempAdmin.obj4")}`)
                        }
                      />
                      <FormControlLabel
                        value="Non-compliance with Privacy Policy"
                        control={<Radio />}
                        label={t("lang.tempAdmin.obj5")}
                        color="gray"
                        onClick={() =>
                          handleReason(true, `${t("lang.tempAdmin.obj5")}`)
                        }
                      />
                      <FormControlLabel
                        value="Vulnerable Email"
                        control={<Radio />}
                        label={t("lang.tempAdmin.obj6")}
                        color="gray"
                        onClick={() =>
                          handleReason(true, `${t("lang.tempAdmin.obj6")}`)
                        }
                      />
                      <FormControlLabel
                        value="others"
                        control={<Radio />}
                        label={t("lang.tempAdmin.obj10")}
                        color="gray"
                        onClick={() =>
                          handleReason(false, `${t("lang.tempAdmin.obj10")}`)
                        }
                      />
                      <TextField
                        id="filled-multiline-static"
                        label=""
                        multiline
                        rows={4}
                        defaultValue=""
                        variant="filled"
                        disabled={isOther}
                        required={true}
                        onChange={(e) => setReason(e.target.value)}
                      />
                    </RadioGroup>
                  </FormControl>
                </CardBody>
                <CardFooter className="pt-0 flex flex-row gap-2">
                  <Button
                    type="button"
                    className="!bg-black text-white"
                    onClick={handleOpenManual}
                    fullWidth
                  >
                    {t("lang.tempAdmin.obj7")}
                  </Button>
                  <Button
                    style={{ backgroundColor: "black", color: "white" }}
                    type="submit"
                    className="!bg-black text-white"
                    fullWidth
                  >
                    {t("lang.tempAdmin.obj8")}
                  </Button>
                </CardFooter>
              </form>
            </Card>
          </Dialog>

          <Card className="shadow-none">
            <CardHeader
              floated={false}
              shadow={false}
              className="rounded-none m-0"
            >
              <div className="flex flex-col justify-between md:flex-row md:items-center p-4 pb-0">
                <Typography variant="h5" color="blue-gray">
                  {t("lang.adminSidebar.text")}
                </Typography>
                <div>
                  {t("lang.tempAdmin.obj9")}
                  <b>{countData}</b>
                </div>
                <div className="w-full md:w-72">
                  <Input
                    id="search"
                    onChange={handleSearch}
                    label={t("lang.tempAdmin.obj11")}
                    icon={<MagnifyingGlassIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </CardHeader>
            {loading ? (
              <div className="w-full flex justify-center">
                <Spinner />
              </div>
            ) : (
              <>
                <CardBody className="px-0">
                  <div className="overflow-auto h-[60dvh]">
                    <table className="w-full pb-6 text-center">
                      <thead>
                        <tr className="border-y border-blue-gray-100 bg-blue-gray-50">
                          {TABLE_HEAD.map((head) => (
                            <th key={head} className="p-4">
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {TABLE_ROWS.map(
                          (
                            {
                              id,
                              no,
                              username,
                              email,
                              temp_email,
                              password,
                              status,
                              lock_status,
                            },
                            index
                          ) => {
                            const isLast = index === TABLE_ROWS.length - 1;
                            const classes = isLast
                              ? "p-4"
                              : "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={no}>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-bold"
                                    >
                                      {no}
                                    </Typography>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {username}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {email}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {temp_email}
                                  </Typography>
                                </td>
                                <td className={classes}>
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                  >
                                    {password}
                                  </Typography>
                                </td>
                                <td className={`${classes} space-y-3`}>
                                  <Chip
                                    size="sm"
                                    variant="ghost"
                                    value={status}
                                    color={
                                      status == "active" ? "green" : "yellow"
                                    }
                                  />
                                  <Chip
                                    size="sm"
                                    variant="ghost"
                                    value={lock_status}
                                    color={
                                      lock_status == "Unlocked" ? "blue" : "red"
                                    }
                                  />
                                </td>
                                {lock_status !== "Unlocked" ? (
                                  <td className={classes}>
                                    <Tooltip content="Unlock Email">
                                      <IconButton
                                        variant="text"
                                        color="blue-gray"
                                        onClick={() => {
                                          unlockEmail({
                                            temp_email_id: id,
                                            reason: "unlocked",
                                            user_id: user.id,
                                            email_lock_status: 2,
                                            type: user.user_type,
                                          });
                                        }}
                                      >
                                        <LockClosedIcon className="h-4 w-4" />
                                      </IconButton>
                                    </Tooltip>
                                  </td>
                                ) : (
                                  <td className={classes}>
                                    <Tooltip content="Lock Email">
                                      <IconButton
                                        variant="text"
                                        color="blue-gray"
                                        onClick={() => setLockEmailInfo(id)}
                                      >
                                        <LockOpenIcon className="h-4 w-4" />
                                      </IconButton>
                                    </Tooltip>
                                  </td>
                                )}
                                <td className={classes}>
                                  <Tooltip content="See Email History">
                                    <IconButton
                                      variant="text"
                                      color="blue-gray"
                                      onClick={() => {
                                        settempemailidinfo(id);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faHistory}
                                        className="h-4 w-4"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <CardFooter className="flex flex-col md:flex-row space-y-3 md:space-y-0 items-center justify-between border-t border-blue-gray-50 p-4">
                  <Button
                    variant="outlined"
                    color="blue-gray"
                    size="sm"
                    onClick={handlePrevious}
                    className="w-full md:w-auto"
                  >
                    {t("lang.pageFooter.obj1")}
                  </Button>
                  <div className="w-full md:w-auto">
                    <Select
                      label={t("lang.pageFooter.obj3")}
                      value={pageSize}
                      onChange={handlePageSize}
                    >
                      <Option value={"5"}>5</Option>
                      <Option value={"10"}>10</Option>
                      <Option value={"20"}>20</Option>
                      <Option value={"50"}>50</Option>
                      <Option value={"100"}>100</Option>
                    </Select>
                  </div>

                  <Button
                    variant="outlined"
                    color="blue-gray"
                    size="sm"
                    onClick={handleNext}
                    className="w-full md:w-auto"
                  >
                    {t("lang.pageFooter.obj2")}
                  </Button>
                </CardFooter>
              </>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

Temporary_admin.propTypes = {
  user: PropTypes.object,
  initEmailList: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  loadEmailHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
  emailList: state.tempReducer.temporary_email,
  historyList: state.tempReducer.email_history,
  userList: state.adminReducer.users,
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, {
  initEmailList,
  loadUserList,
  changeStatus,
  lockEmail,
  unlockEmail,
  loadEmailHistory,
  logout,
})(Temporary_admin);
