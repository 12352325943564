import React, { Component } from "react";
import Email from "./Email";
import Otpverify from "./Otpverify";
import Mobile from "./Mobile";
import Name from "./Name";
import Policy from "./Policy";

export default class Signup extends Component {
  state = {
    step: 1,
    email: "",
    country_code: "",
    mobile: "",
    first_name: "",
    last_name: "",
  };

  //removing Lang buttons
  removeLang = (step) => {
    let btnGroup = document.getElementById("btnGroup");
    if (step == 1) {
      if (btnGroup != null && btnGroup.classList.contains("hidden")) {
        btnGroup.classList.remove("hidden");
      }
    } else {
      btnGroup.classList.add("hidden");
    }
  };

  // go back to previous step
  prevStep = () => {
    const { step } = this.state;
    this.setState({ step: step - 1 });
  };

  // proceed to the next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  // handle field change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  render(isAuthenticated, loading) {
    const { step } = this.state;

    switch (step) {
      case 1:
        return (
          <>
            {this.removeLang(step)}
            <Email nextStep={this.nextStep} state={this.state} />
          </>
        );
      case 2:
        return (
          <>
            {this.removeLang(step)}
            <Otpverify
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              state={this.state}
            />
          </>
        );
      case 3:
        return (
          <>
            {this.removeLang(step)}
            <Mobile
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              state={this.state}
            />
          </>
        );
      case 4:
        return (
          <>
            {this.removeLang(step)}
            <Name
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              state={this.state}
            />
          </>
        );
      case 5:
        return (
          <>
            {this.removeLang(step)}
            <Policy
              prevStep={this.prevStep}
              nextStep={this.nextStep}
              state={this.state}
            />
          </>
        );
      default:
      // do nothing
    }
  }
}
