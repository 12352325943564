import { useState } from "react";

import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card, Input, Button, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import OtpInput from "react-otp-input";

import { loginUser } from "../../actions/authAction";
import { otpVerify } from "../../actions/authAction";
import { setAlert } from "../../actions/alertAction";

import Spinner from "../layout/Spinner";

const Login = ({
  isAuthenticated,
  user_type,
  loading,
  loginUser,
  otpVerify,
}) => {
  const { t } = useTranslation();

  const [email1, setEmail1] = useState("");
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");

  const Continue = (e) => {
    e.preventDefault();
    loginUser(email1)
      .then((res) => {
        if (res.data.key) setShow(true);
      })
      .catch((err) => {
        if (err) setShow(false);
      });
  };

  const resendOtp = () => {
    setOtp("");
    loginUser(email1);
  };

  const verifyOtp = () => {
    otpVerify(email1, Number(otp), "login");
  };

  if (isAuthenticated) {
    if (user_type.id == 2 && user_type.user_type == "user")
      return <Navigate to="/temporary" />;
    else if (user_type.id == 1 && user_type.user_type == "admin")
      return <Navigate to="/user_mng" />;
  }
  return (
    <Card
      color="transparent"
      shadow={false}
      className="container mx-auto my-4 px-12 py-10 sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[60%] 2xl:w-[45%]
        min-h-screen"
    >
      <div className="flex items-center justify-center relative">
        {loading ? (
          ""
        ) : show ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#000000"
            className="w-5 h-5 bg-transparent absolute left-[15%]"
            onClick={() => setShow(false)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
            />
          </svg>
        ) : (
          ""
        )}

        <Typography
          variant="h2"
          color="blue-gray"
          className="flex justify-center py-3"
        >
          {t("lang.login.text")}
        </Typography>
      </div>

      {loading ? (
        <Spinner />
      ) : show ? (
        <div className="flex flex-col items-center space-y-3">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderInput={(props) => (
              <input
                {...props}
                className="border-none rounded-xl !w-12 h-12 mx-1 bg-gray-200"
              />
            )}
          />
          <div className="flex justify-around w-full">
            <Button
              className="rounded-full bg-gray-200 text-black px-4 shadow-none hover:shadow-none"
              onClick={resendOtp}
            >
              {t("lang.email_verification.button_1")}
            </Button>
            <Button
              className="rounded-full bg-gray-200 text-black px-4 shadow-none hover:shadow-none"
              onClick={verifyOtp}
            >
              {t("lang.email_verification.button_3")}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center p-4 space-y-4">
          <div className="px-6 w-full space-y-4">
            <Input
              label={t("lang.signup_1.placeholder1")}
              type="email"
              value={email1}
              onChange={(e) => setEmail1(e.target.value)}
            />
          </div>
          <Button
            fullWidth
            onClick={Continue}
            className="bg-black hover:shadow-none shadow-none border-none
        disabled:opacity-30"
          >
            {t("lang.signup_1.button")}
          </Button>
        </div>
      )}
    </Card>
  );
};

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  otpVerify: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  user_type: state.authReducer.user_type,
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, { otpVerify, loginUser })(Login);
