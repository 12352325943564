import React, { useState, useEffect } from "react";
import { Input, Button, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

import { otpSend } from "../../../actions/authAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Email = ({ nextStep, state, otpSend }) => {
  const [email1, setEmail1] = useState("");
  const [email2, setEmail2] = useState("");
  const [valid, setValid] = useState(false);
  const { t } = useTranslation();

  const Continue = (e) => {
    e.preventDefault();
    state.email = email2;
    otpSend(state.email)
      .then((res) => {
        if (res.data.key) nextStep();
      })
      .catch((err) => {
        if (err) console.log(err);
      });
  };

  //For not allowing copy and paste the email
  const preventCopy = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    if (email1 !== email2 || email1 === "" || email2 === "") {
      setValid(false);
    } else {
      setValid(true);
    }
  }, [email1, email2]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="max-w-5xl flex flex-col items-center p-4 space-y-4">
        <Typography variant="h3" className="text-center px-12">
          {t("lang.signup_1.question")}
        </Typography>
        <div className="px-6 w-full space-y-4">
          <Input
            label={t("lang.signup_1.placeholder1")}
            type="email"
            value={email1}
            onCopy={preventCopy}
            onChange={(e) => setEmail1(e.target.value)}
          />
          <Input
            label={t("lang.signup_1.placeholder2")}
            type="email"
            value={email2}
            onChange={(e) => setEmail2(e.target.value)}
          />
        </div>
        <Button
          fullWidth
          disabled={!valid}
          onClick={Continue}
          className="bg-black hover:shadow-none shadow-none border-none
        disabled:opacity-30"
        >
          {t("lang.signup_1.button")}
        </Button>
      </div>
    </div>
  );
};

Email.propTypes = {
  otpSend: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  user_type: state.authReducer.user_type,
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, { otpSend })(Email);
