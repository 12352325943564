import React, { useReducer, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const Permission = ({ children, permit, user }) => {
  console.log("USER PERMIT - ", permit in user.permissions, user.permissions);

  return <>{permit in user.permissions ? children : ""}</>;
};

Permission.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.authReducer.user,
});

export default connect(mapStateToProps)(Permission);
