import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Typography, Button } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { otpSend } from "../../../actions/authAction";
import { otpVerify } from "../../../actions/authAction";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import Spinner from "../../layout/Spinner";

const Otpverify = ({
  prevStep,
  nextStep,
  state,
  otpSend,
  otpVerify,
  loading,
}) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");

  const resendOtp = () => {
    setOtp("");
    otpSend(state.email);
  };

  const Continue = (e) => {
    e.preventDefault();
    otpVerify(state.email, Number(otp), "register")
      .then((res) => {
        if (res) nextStep();
      })
      .catch((err) => "");
  };

  const Previous = (e) => {
    e.preventDefault();
    prevStep();
  };

  return (
    <div className="flex flex-col items-center space-y-2  justify-center min-h-screen">
      <div className="max-w-sm flex flex-col p-4 space-y-6">
        <Typography variant="h3">
          {t("lang.email_verification.text", { username: state.email })}
        </Typography>

        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderInput={(props) => (
            <input
              {...props}
              className="border-none rounded-xl !w-12 h-12 mx-1 bg-gray-200"
            />
          )}
        />

        <Typography
          variant="small"
          color="gray"
          className="mt-2 flex items-center gap-1 font-normal"
        >
          {t("lang.email_verification.text2")}
        </Typography>
        {loading ? (
          <Spinner />
        ) : (
          <div className="flex gap-2">
            <Button
              className="rounded-full bg-gray-200 text-black px-4 shadow-none hover:shadow-none"
              onClick={resendOtp}
            >
              {t("lang.email_verification.button_1")}
            </Button>

            {/* <Button
              className="rounded-full bg-gray-200 text-black px-4 shadow-none hover:shadow-none"
              onClick={verifyOtp}
            >
              {t("lang.email_verification.button_3")}
            </Button> */}
          </div>
        )}

        <div className="flex justify-between w-full pt-12">
          <button
            onClick={Previous}
            className="p-4 bg-gray-200 rounded-full border-none shadow-none hover:shadow-none"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
          </button>
          <button
            onClick={Continue}
            className="p-4 bg-gray-200 rounded-full border-none shadow-none hover:shadow-none disabled:opacity-30"
          >
            {t("lang.email_verification.button_2")}
          </button>
        </div>
      </div>
    </div>
  );
};

Otpverify.propTypes = {
  otpVerify: PropTypes.func.isRequired,
  otpSend: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
  user_type: state.authReducer.user_type,
  loading: state.loadReducer.loading,
});

export default connect(mapStateToProps, { otpSend, otpVerify })(Otpverify);
